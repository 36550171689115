import React from 'react'

import { Input } from '@mui/material';
import { Button } from '@mui/material';
import { useState } from 'react';

import { createWaitlistRecord } from "../../../apis/airtable"

import './WaitingListForm.css'

const WaitingListForm = () => {

  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const onChange = (event) => {
    setEmail(event.target.value)
  }

  const onSubmit = (event) => {
    event.preventDefault();

    let recordData ={
      "records": [{
        'fields': {'email': email}
    }]}

    createWaitlistRecord(recordData)
    .then(ok => {
      setSubmitted(true)
    })
    .catch((err) => {
      console.error("error");
      console.error(err)
    })
    event.target.reset();
  }

  return (
    <>
      <form onSubmit={onSubmit} className='waitlist'>
        <Input id="waitlist-email" placeholder="name@email.com" onChange={onChange} />
        <Button variant="contained" color="secondary" type="submit">Submit</Button>
      </form>
      { submitted && <span>thanks! we'll let you know when we're ready:)</span> }
    </>
  )
}


export default WaitingListForm