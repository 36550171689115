import axios from 'axios';

export const createWaitlistRecord = (data) => {
  
  const headers = {
    'Content-Type': 'application/json',
    Authorization : `Bearer ${process.env.REACT_APP_AIRTABLE_PAT}`
  }

  let config = {
    headers: headers
  };
  
  const base_url = `https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_WAITLIST_BASE_ID}/${process.env.REACT_APP_AIRTABLE_WAITLIST_SHEET_ID}`

  return axios.post(base_url, data, config)
}

