import React from 'react'
import './Hero.css'
import { WaitingListForm } from '../Forms/formsindex'



const Hero = ({props}) => {
  return (
    <div className='hero-bg'>
      <div className="hero-container">
        <div className="hero-text">
          <h1>topnotes</h1>
          <h3>coming soon</h3>
          <p>join the waiting list</p>
        </div>
        < WaitingListForm />
      </div>
    </div>
  )
}

export default Hero